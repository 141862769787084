import * as React from "react";
import Layout from "../components/Layout";
import { HtmlHead, ResponsiveImage, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { graphql } from "gatsby";
import NewsList from "../components/NewsList";

export const Head = () => (
  <HtmlHead title="Úvodná stránka | Metodické centrum pro dopravní psychologii">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

export default function IndexPage({ data }) {
  const box = {
    paddingTop: "0px !important",
    marginTop: "0px",
    div: {
      border: "2px solid #0053a1",
      textAlign: "center",
      color: "white",
      padding: "30px 0px",
      borderRadius: "16px",
      background: "#0053a1",
      fontWeight: "600",
      transitionDuration: "0.3s",
    },
    "div:hover": {
      border: "2px solid #0053a1",
      background: "#0053a19c",
      transitionDuration: "0.3s",
    },
  };
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
    ".customLink a": {
      textDecoration: "none",
      color: "white",
      backgroundColor: "#0053a1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid #0053a1",
      transitionDuration: "0.3s",
      width: "fit-content",
      padding: "5px 15px",
      borderRadius: "8px",
    },
    ".customLink a:hover": {
      transitionDuration: "0.3s",
      backgroundColor: "white",
      color: "#0053a1",
    },
  };
  const mainCss = {
    marginTop: "96px",
    ".mainDiv": {
      marginTop: "0px !important",
    },
    ".mainVideo": {
      position: "relative",
      top: "0px",
      height: { lg: "650px", xs: "600px" },
      width: "100%",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    ".mainVideo .video video": {
      position: "absolute",
      top: "0px",
      height: "100%",
      width: "100%",
      objectPosition: "center",
      objectFit: "cover",
      zIndex: "1",
    },
    ".mainVideo .shadow": {
      position: "absolute",
      zIndex: "2",
      top: "0px",
      height: "100%",
      width: "100%",
      backgroundColor: "#0053A1",
      opacity: "0.6",
    },
    ".mainVideo .content": {
      position: "absolute",
      top: "0px",
      height: "100%",
      width: "100%",
      zIndex: "3",
      color: "white",
    },
  };
  const Blog = {
    borderRadius: "12px",
    div: {},
    img: {
      borderRadius: "12px",
    },
    ".link": {
      marginTop: "20px",
    },
    ".link a": {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#0053a1",
      fontWeight: "600",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
      width: "fit-content",
    },
    ".link a:hover": {
      borderBottom: "1px solid #0053a1",
      transitionDuration: "0.3s",
    },
  };

  const sectionA = {
    a: {
      color: "#8A8B8D !important",
    },
    "a:hover": {
      borderBottom: "1px solid #8A8B8D",
    },
  };
  return (
    <Box sx={mainCss}>
      <Layout isIndex>
        <Box className="mainVideo">
          <Box className="video">
            <video playsInline autoPlay loop muted>
              <source src="./main-video.mp4" type="video/mp4" />
            </video>
          </Box>
          <Box className="shadow"></Box>
          <Box className="content">
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Box>
                <StaticImage src="../images/logo-metodika.svg" alt="" height={280}></StaticImage>
              </Box>
            </Container>
          </Box>
        </Box>
        <Container sx={aCss}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    lg: "36px",
                    xs: "24px",
                    textAlign: "center",
                    color: "black",
                  },
                }}
              >
                Metodické centrum pro
                <br /> dopravní psychlogii a jeho účel
              </Typography>
              <Typography sx={{ textAlign: "center" }}>
                Jde o odborné pracoviště Centra dopravního výzkumu zaměřené na přípravu a koordinaci
                terapeutických programů pro řidiče.
                <br />
                Jeho hlavním úkolem je:{" "}
              </Typography>
              <Box>
                <ul style={{ textAlign: "center", listStylePosition: "inside" }}>
                  <li>metodické vedení terapeutických programů </li>
                  <li>vstupní a průběžné vzdělávání lektorů terapeutických programů </li>
                  <li>udělování a odnímání akreditací lektorů </li>
                  <li>kontrola kvality poskytovaných programů</li>
                  <li>provoz informačního internetového portálu</li>
                </ul>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={sectionA}>
                  <Link to="/o-nas">více o nás {">>"}</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box>
          <Box sx={{ background: "#f5f5f5", paddingTop: "60px", marginTop: "80px" }}>
            <Container>
              <Typography
                variant="h2"
                sx={{
                  fontSize: "40px",
                  marginTop: "0px",
                  textAlign: "center",
                  color: "black",
                  fontWeight: "600",
                }}
              >
                Novinky
              </Typography>
              <Box sx={{ marginTop: "30px", paddingBottom: "60px" }}>
                <NewsList size={3}></NewsList>
              </Box>
            </Container>
          </Box>
        </Box>
        <Box sx={aCss}>
          <Container sx={{ marginTop: "120px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                gap: "60px",
                flexDirection: { md: "row", xs: "column" },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <span style={{ textAlign: "center", fontSize: "32px", fontWeight: "bold" }}>
                  pro řidiče
                </span>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box className="customLink" sx={{ paddingTop: "10px" }}>
                    <a style={{ fontSize: "14px", fontWeight: "bold" }} href="">
                      absolvovat kurz
                    </a>
                  </Box>
                </Box>
                <Box sx={{ paddingTop: "30px" }}>
                  <StaticImage src="../images/ridic.svg" height={230} alt=""></StaticImage>
                </Box>
              </Box>
              <Box>
                <span style={{ textAlign: "center", fontSize: "32px", fontWeight: "bold" }}>
                  pro psychology
                </span>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box className="customLink" sx={{ paddingTop: "10px" }}>
                    <a style={{ fontSize: "14px", fontWeight: "bold" }} href="">
                      chci být lektorem
                    </a>
                  </Box>
                </Box>
                <Box sx={{ paddingTop: "30px" }}>
                  <StaticImage src="../images/lektor.svg" height={230} alt=""></StaticImage>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Layout>
    </Box>
  );
}

// export const query = graphql`
//   query NewsList {
//     allRestApiSecureApiNewsList(limit: 3) {
//       edges {
//         node {
//           data {
//             content
//             image
//             title
//             id
//           }
//         }
//       }
//     }
//   }
// `;
